import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../../api/authApi";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import App_logo from "assets/images/logo.png";
// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";

function LogIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const vendorPassword = process.env.REACT_APP_VENDOR_PASSWORD;
  
  const handleSignIn = async (event) => {
    event.preventDefault();

    const data = {
      action: "login",
      username: email,
      password: password,
    };

    try {
      const user = await login(data);
      if (!user.status) {
        throw new Error(user.message);
      }

      navigate("/dashboard");
    } catch (err) {
      alert("Invalid credentials. Please try again.");
    }
  };

  return (
    <CoverLayout
      title={(
        <SoftBox 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'flex-start',  // Aligns the items to the top
            position: 'relative', 
            top: '-70px'              // Adjusts the top position
          }} 
        >
          <SoftBox component={Link} to="/">
            <img 
              src={App_logo} 
              alt="App Logo" 
              style={{ width: "300px", height: "100px" }} 
            />
          </SoftBox>
        </SoftBox>
      )}
      
    >
      <SoftBox component="form" role="form" onSubmit={handleSignIn} mt={-3}>
        <SoftBox mb={3}>
         
          <SoftInput 
            type="text"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </SoftBox>
        <SoftBox mb={3}>
          
          <SoftInput
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={3} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth type="submit">
            Log In
          </SoftButton>
        </SoftBox>
        <SoftBox mt={2} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default LogIn;

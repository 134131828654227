/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function PlatformSettings() {
  const [followsMe, setFollowsMe] = useState(true);
  const [answersPost, setAnswersPost] = useState(false);
  const [mentionsMe, setMentionsMe] = useState(true);
  const [newLaunches, setNewLaunches] = useState(false);
  const [productUpdate, setProductUpdate] = useState(true);
  const [newsletter, setNewsletter] = useState(true);

  // return (
  //   <Card>
  //     <SoftBox pt={2} px={2}>
  //       <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
  //         platform settings
  //       </SoftTypography>
  //     </SoftBox>
  //     <SoftBox pt={1.5} pb={2} px={2} lineHeight={1.25}>
  //       <SoftTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
  //         account
  //       </SoftTypography>
  //       <SoftBox display="flex" py={1} mb={0.25}>
  //         <SoftBox mt={0.25}>
  //           <Switch checked={followsMe} onChange={() => setFollowsMe(!followsMe)} />
  //         </SoftBox>
  //         <SoftBox width="80%" ml={2}>
  //           <SoftTypography variant="button" fontWeight="regular" color="text">
  //             Email me when someone follows me
  //           </SoftTypography>
  //         </SoftBox>
  //       </SoftBox>
  //       <SoftBox display="flex" py={1} mb={0.25}>
  //         <SoftBox mt={0.25}>
  //           <Switch checked={answersPost} onChange={() => setAnswersPost(!answersPost)} />
  //         </SoftBox>
  //         <SoftBox width="80%" ml={2}>
  //           <SoftTypography variant="button" fontWeight="regular" color="text">
  //             Email me when someone answers on my post
  //           </SoftTypography>
  //         </SoftBox>
  //       </SoftBox>
  //       <SoftBox display="flex" py={1} mb={0.25}>
  //         <SoftBox mt={0.25}>
  //           <Switch checked={mentionsMe} onChange={() => setMentionsMe(!mentionsMe)} />
  //         </SoftBox>
  //         <SoftBox width="80%" ml={2}>
  //           <SoftTypography variant="button" fontWeight="regular" color="text">
  //             Email me when someone mentions me
  //           </SoftTypography>
  //         </SoftBox>
  //       </SoftBox>
  //       <SoftBox mt={3}>
  //         <SoftTypography
  //           variant="caption"
  //           fontWeight="bold"
  //           color="text"
  //           textTransform="uppercase"
  //         >
  //           application
  //         </SoftTypography>
  //       </SoftBox>
  //       <SoftBox display="flex" py={1} mb={0.25}>
  //         <SoftBox mt={0.25}>
  //           <Switch checked={newLaunches} onChange={() => setNewLaunches(!newLaunches)} />
  //         </SoftBox>
  //         <SoftBox width="80%" ml={2}>
  //           <SoftTypography variant="button" fontWeight="regular" color="text">
  //             New launches and projects
  //           </SoftTypography>
  //         </SoftBox>
  //       </SoftBox>
  //       <SoftBox display="flex" py={1} mb={0.25}>
  //         <SoftBox mt={0.25}>
  //           <Switch checked={productUpdate} onChange={() => setProductUpdate(!productUpdate)} />
  //         </SoftBox>
  //         <SoftBox width="80%" ml={2}>
  //           <SoftTypography variant="button" fontWeight="regular" color="text">
  //             Monthly product updates
  //           </SoftTypography>
  //         </SoftBox>
  //       </SoftBox>
  //       <SoftBox display="flex" py={1} mb={0.25}>
  //         <SoftBox mt={0.25}>
  //           <Switch checked={newsletter} onChange={() => setNewsletter(!newsletter)} />
  //         </SoftBox>
  //         <SoftBox width="80%" ml={2}>
  //           <SoftTypography variant="button" fontWeight="regular" color="text">
  //             Subscribe to newsletter
  //           </SoftTypography>
  //         </SoftBox>
  //       </SoftBox>
  //     </SoftBox>
  //   </Card>
  // );
}

export default PlatformSettings;
